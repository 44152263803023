import React from 'react';
import { FaGithub, FaTwitter, FaLinkedin } from "react-icons/fa";

import SubPage from "../components/subpage";
import styles from './contact.module.css';

const ContactPage = () => {
    return (
        <SubPage title='contact'>
            <div className={styles.contact}>
                <h3 className={styles.email}>
                    <a href="mailto:dave.sohan@gmail.com" target="_blank" rel="noopener noreferrer" className={styles.address} >dave.sohan@gmail.com</a>
                </h3>
                <div className={styles.social}>
                    <ul className={styles.socialList}>
                        <li id="github"><a href="https://github.com/davehowson" target="_blank" rel="noopener noreferrer"><FaGithub /></a></li>
                        <li id="twitter"><a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a></li>
                        <li id="linkedin"><a href="https://www.linkedin.com/in/dave-howson/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a></li>
                    </ul>
                </div>
            </div>
        </SubPage>
    )
}

export default ContactPage;
